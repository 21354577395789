body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-background-default;
  direction: rtl;
}

#docs-root {
  direction: ltr;
}
/** hide arrows from number input **/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/**END*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
* {
  box-sizing: border-box;
  outline: none;
}
a {
  color: inherit;
  text-decoration: none !important;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
}

button,
input[type="button"] {
  outline: none !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

/*input:-internal-autofill-selected,*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 9999s, color 9999s ease-in-out 9999s;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none !important;
}
input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}
